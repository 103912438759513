export const common = {
  data () {
    return {
      userRole: JSON.parse(localStorage.getItem('cdpUser')).role
    }
  },
  methods: {
    /* 이전 페이지로 이동 */
    beforePage () {
      this.$router.go(-1)
    },
    /* 숫자 3자리마다 , 넣기 */
    numReplace (val) {
      if (val === null) {
        return 0
      } else {
        let num = val + ''
        num = num.split('.')
        let result = num[0].replace(/[^0-9-]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (num[1]) {
          result += '.' + num[1]
        }
        return result
      }
    },
    /* 합계 계산 */
    totalReplace (x, y, z) {
      let val = Number(x) + Number(y) + Number(z)
      return val
    },
    /* 퍼센트 계산 */
    percentReplace (x, y) {
      let val = Number(x) / Number(y) * 100
      return val.toFixed(0)
    },
    /* 초값으로 시분초 계산 */
    timeSet: function (val) {
      val = Number(val)
      let hour = parseInt(val / 3600) + ''
      let min = parseInt((val % 3600) / 60) + ''
      if (min.length === 1) {
        min = 0 + min
      }
      let sec = val % 60 + ''
      if (sec.length === 1) {
        sec = 0 + sec
      }
      return this.numReplace(hour) + '시간 ' + min + '분 ' + sec + '초'
    },
    /* 뷰당단가 계산 소수점 2자리까지 */
    toFixed (x, y) {
      if (x === 0 || y === 0) {
        return 0
      } else if (x === '0' || y === '0') {
        return 0
      } else {
        let val = x / y
        return val.toFixed(2)
      }
    },
    /* 엔티티코드를 특수문자로 변경 */
    convertHtml (str) {
      str = str.replace(/&amp;/g, '&')
      str = str.replace(/&lt;/g, '<')
      str = str.replace(/&gt;/g, '>')
      str = str.replace(/&quot;/g, '"')
      str = str.replace(/&#039;/g, "'")
      str = str.replace(/&#39;/g, "'")
      str = str.replace(/\n/ig, '')
      return str
    },
    /**
     * RouteName의 Suffix가 str과 같은지 여부를(같으면 true, 다르면 false) 반환
     *
     * @param str
     * @param ignoreCase
     * @returns {boolean}
     */
    routeSuffixIs (str, ignoreCase = true) {
      let routeName = this.$route.name
      let cmp = routeName.substring(routeName.length - str.length)

      if (ignoreCase) {
        cmp = cmp.toLowerCase()
        str = str.toLowerCase()
      }

      if (cmp === str) {
        return true
      } else {
        return false
      }
    }
  }
}
