<!-- CP 등록/수정 -->
<template>
  <v-container
    id="register"
    fluid
    tag="section">
    <v-row
      align="center"
      justify="center">
      <v-col cols="12">
        <base-material-card
          color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              CP > 크롤링 > 키워드 필터 > {{formModeText()}}
            </h1>
          </template>
          <v-card-text>
            <v-form
              v-model="vModel.valid"
              ref="form">
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="2" class="text-right grey--text">
                  CP
                </v-col>
                <v-col cols="5">
                  <v-select
                    :items="config.partnersOptions"
                    v-model="vModel.data.partner_id"
                    label="CP명"
                    color="secondary"
                    :disabled="this.routeSuffixIs('edit')" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="2" class="text-right grey--text">
                  대상
                </v-col>
                <v-col cols="5">
                  <v-select :items="config.targetTypes"
                            v-model="vModel.data.type"
                            placeholder="키워드 필터링 대상 유형"
                            color="secondary" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="2" class="text-right grey--text">
                  필터할 키워드
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    placeholder="키워드 리스트"
                    v-model="vModel.data.keywords " />

                  <ul class="blockquote body-2">
                    <li>동의어나 유사어를 한 줄에 하나씩 등록해 주세요</li>
                    <li>내부적으로 "|"를 구분자로 사용하므로, 키워드 지정 시 유의해 주세요</li>
                    <li>정규표현식 형식으로 작성 가능 (eg. 오늘의\s*퀴즈 => [오늘의퀴즈 | 오늘의 퀴즈 | 오늘의      퀴즈])</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="2" class="text-right grey--text">
                  정규표현식 옵션
                </v-col>
                <v-col cols="5">
                  <v-checkbox v-model="vModel.data.ignore"
                              label="Ignore case"
                              value="I"/>
                </v-col>
              </v-row>

              <v-row justify="center" class="mt-5">
                <v-btn
                  class="mt-5"
                  color="default"
                  @click="beforePage">
                  취소
                </v-btn>

                <template v-if="this.routeSuffixIs('Register')">
                  <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="success"
                    @click="submitRegister">
                    등록
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="success"
                    @click="submitEdit">
                    수정
                  </v-btn>

                  <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="error"
                    @click="submitDelete">
                    삭제
                  </v-btn>
                </template>
              </v-row>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '@/mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        partners: null,
        register: null,
      },
      vModel: {
        valid: true,
        // linkBool: true,
        data: {
          mode: 'edit',
          ignore: '',
        },
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null
      },
      config: {
        targetTypes: [
          { value: 'title', text: '제목' },
          { value: 'body', text: '본문' },
          { value: 'section', text: '섹션' },
        ],
        partnersOptions: [],
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    apiUrl (id = '') {
      let urlPrefix = '/partners/crawlings/filter_keywords'
      if (id) {
        return urlPrefix + '/' + id
      }
      return urlPrefix
    },
    /* 페이지 로드시 */
    refresh () {
      this.getPartnerList()
      if (this.routeSuffixIs('Edit')) {
        this.getForm()
      }
    },
    /* CP 리스트 가져오기 */
    getPartnerList () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'name',
        direction: 'asc',
        resolution: 'lo',
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partners = response.data.data.partners
          this.config.partnersOptions = this.response.partners.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })
          this.config.partnersOptions.unshift({ value: null, text: '전체' })
        }
      }).catch((error) => {
        console.log(error)
        this.snack('warning', '관리자에게 문의 바랍니다.')
      })
    },
    /* form 상세정보 가져오기 */
    getForm () {
      let id = this.$route.params.filter_keyword_id
      this.xhttp({
        url: this.apiUrl(id),
        method: 'get',
        params: null,
        self: this,
      }).then((response) => {
        if (response.status === 200) {
          this.vModel.data = response.data.data.data
          for (let regexFlag of this.vModel.data.regex_flags.split('|')) {
            if (regexFlag === 'I') {
              this.vModel.data.ignore = 'I'
            } else {
              this.vModel.data.ignore = ''
            }
          }
        }
      }).catch((error) => {
        if ('catchPreProcessed' in error && error.catchPreProcessed) {
          return
        }

        console.log(error)
        this.snack('warning', '관리자에게 문의 바랍니다.')
      })
    },
    /* 크롤링 키워드 필터 등록하기 */
    submitRegister () {
      if (this.$refs.form.validate()) {
        if (confirm('등록 하시겠습니까?')) {
          let send = this.vModel.data

          this.xhttp({
            url: this.apiUrl(),
            method: 'post',
            data: send,
            self: this,
          }).then((response) => {
            if (response.status === 200) {
              this.response.register = response.data.data
              this.snack('success', '등록이 완료되었습니다.')
              setTimeout(function (router) {
                router.push({ name: 'partnersCrawlingFilterKeywordList' })
              }, 1500, this.$router)
            }
          }).catch((error) => {
            console.log(error)
            this.snack('warning', '관리자에게 문의 바랍니다.')
          })
        }
      }
    },
    /* 수정 버튼 클릭시 */
    submitEdit () {
      if (this.$refs.form.validate()) {
        this.putForm()
      }
    },
    submitDelete () {
      let id = this.$route.params.filter_keyword_id
      if (!confirm('정말 삭제 하시겠습니까?')) {
        return null
      }

      this.xhttp({
        url: this.apiUrl(id),
        method: 'delete',
        data: null,
        self: this,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.data.data) {
            this.beforePage()
          } else {
            this.snack('warning', '삭제 실패! 관리자에게 문의 바랍니다.')
          }
        }
      }).catch((error) => {
        if ('catchPreProcessed' in error && error.catchPreProcessed) {
          return
        }

        console.log(error)
        this.snack('warning', '관리자에게 문의 바랍니다.')
      })
    },
    /* 수정하기 */
    putForm () {
      if (confirm('수정 하시겠습니까?')) {
        let id = this.$route.params.filter_keyword_id
        let send = this.vModel.data
        this.xhttp({
          url: this.apiUrl(id),
          method: 'put',
          data: send,
          self: this,
        }).then((response) => {
          if (response.status === 200) {
            this.response.edit = response.data.data
            this.snack('success', '수정 완료되었습니다.')
            this.beforePage()
          }
        }).catch((error) => {
          console.log(error)
          this.snack('warning', '관리자에게 문의 바랍니다.')
        })
      }
    },
    formModeText () {
      if (this.routeSuffixIs('register')) {
        return '신규 등록'
      } else {
        return '수정'
      }
    }
  }
}
</script>
